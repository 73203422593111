<template>
  <span>
    <span v-if="isUserAccepter(etatInscription)" style="color:#52c41a">
      <a-icon
        type="check-circle"
        theme="twoTone"
        twoToneColor="#52c41a"
        class="margin_right"
      />Accepté
    </span>
    <span v-if="isUserRefuser(etatInscription)" style="color:#eb2f96">
      <a-icon type="close-circle" class="margin_right" />Refusé
    </span>
    <span v-if="isUserEnAttente(etatInscription)">
      <a-icon type="clock-circle" class="margin_right" />En attente
    </span>
    <span v-if="isUserNonSoumis(etatInscription)">
      <a-icon type="snippets" class="margin_right" />Non soumis
    </span>
  </span>
</template>
<script>
export default {
  name: "EtatInscriptionIndicator",
  props: { etatInscription: Number }
};
</script>